/**
 * Created by Bo on 17-Jul-17.
 */
import { environment } from '../../../environments/environment';
import {
    PlanDurations,
    PlanMonthlyPrices,
    PlanTotalPrices,
    PlanTotalPricesUndiscounted,
    SubscriptionPlan,
    SubscriptionPlanType,
    SubscriptionPlans
} from '../../models/subscription.model';

export class AppSettings {
    public static API_ROOT = environment.apiRoot;
    public static CLIENT_ROOT = environment.clientRoot;
    public static DEFAULT_USER_IMAGE_URL = environment.rootCDN + '/images/users/default.png';
    public static DEFAULT_TEAM_IMAGE_URL = environment.rootCDN + '/images/teams/default.jpeg';
    public static DEFAULT_PLAYER_IMAGE_URL = environment.rootCDN + '/images/players/default.jpeg';

    public static LOGIN_CREDENTIAL_REGEX: RegExp = /^[\w.-]{3,20}$|^[^@]+@[^@]+$/;
    public static USERNAME_REGEX: RegExp = /^([a-z0-9]){3,15}$/i;

    // tslint:disable-next-line
    public static EMAIL_REGEX: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static PASSWORD_REGEX: RegExp = /^.{6,48}$/;
    public static DOB_REGEX: RegExp = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/;
    public static MONTH_YEAR_REGEX: RegExp = /(0[1-9]|1[012])[- \/.]\d\d/;
    public static TIME_FORMAT_REGEX: RegExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/; // works for HH:MM:SS  / HH:MM
    public static DURATION_FORMAT_REGEX: RegExp = /^(\d+(?=:))?(\d+(?=:))?(\d+)$/; // has issues
    public static ASYNC_VALIDATORS_TIMEOUT: number = 600;

    public static GOOGLE_CLIENT_ID: string =
        '952619160805-4fq7h13v53in0tl3022b7jcfv48agfma.apps.googleusercontent.com';


    public static HEADER_HEIGHT: number = 50;
    public static DEFAULT_VIDEO_GUIDE_RELEASE_TIME: string[] = ['22', '00'];
    public static PRIMARY_COLOR_HEX: string = '#0EA5D9';
    public static PRIMARY_COLOR_RGB: string = '14, 165, 217';
    public static FB_APP_ID: string = '334927470262950';
    public static FP_APP_VERSION: string = 'v3.0';
    public static SITE_KEY: string = '6LcSHmYUAAAAAN8VKF4npJ-DXYqRKe-ujIEp_ori';
    public static GAPI_URL: string = 'https://apis.google.com/js/platform.js';
    public static GRECAPTCHA_URL: string = 'https://www.google.com/recaptcha/api.js';
    public static RECAPTCHA_V3_SITE_KEY: string = '6Lfehd8jAAAAAOEexEKg73rXBNgzMy6x71R0GBVF';
    // needed for Push notifications
    public static PUBLIC_VAPID_KEY: string =
        'BEnVDLLO67zAu-Af-goLK7qkOnTHIFzP0mGwzdWE1aB85BgFKKZj_-jzhEov1sjHvtTNI309BXH8vyjk_i0QDtY';
    public static ANNOUNCEMENT_EXPIRATION_DATE: Date = new Date(2022, 1, 25, 11, 59, 59);
    public static ANNOUNCEMENT_NAME: string = 'APEX_S15';
    public static ANNOUNCEMENT_GAMES: string[] = ['dota', 'league', 'fortnite', 'overwatch', 'overwatch2', 'valorant', 'apex', 'halo'];
    public static ANNOUNCEMENT_REQUIRE_LOGIN: boolean = false;
    public static SIGNUP_DISABLED: boolean = false;

    // shows a banner above the header with custom promo html & code
    public static PROMO_BANNER_SNOW_PARTICLES_ENABLED: boolean = false;
    public static PROMO_DISCOUNTED_PLAN: SubscriptionPlans = SubscriptionPlans.TwelveMonth;

    public static DISCORD_CDN_BASE_URL: string = 'https://cdn.discordapp.com';
    public static DISCORD_SERVER_INVITE_ID: string = 'YXNXNmh';
    public static DISCORD_GAMELEAP_SERVER_ID: string = '549668544477134854';
    public static DISCORD_DOTA_MAIN_CHANNEL: string = '550022552282464300';
    public static DISCORD_OW_MAIN_CHANNEL: string = '550022570708172820';
    public static DISCORD_LEAGUE_MAIN_CHANNEL: string = '550022521332826123';
    public static DISCORD_FORTNITE_MAIN_CHANNEL: string = '623138431266062338';
    public static DISCORD_VALORANT_MAIN_CHANNEL: string = '698168304568893451';

    public static MIN_START_TIME_TRESHOLD_SECONDS: number = 15;

    public static SUBSCRIPTION_PLANS: SubscriptionPlan[] = [
        {
            planId: SubscriptionPlans.OneMonth,
            name: 'Monthly',
            type: SubscriptionPlanType.OneMonth,
            pricePerMonth: PlanMonthlyPrices.OneMonth,
            totalPrice: PlanMonthlyPrices.OneMonth,
            duration: PlanDurations.OneMonth,
            iconClass: 'circle-1-12-icon',
            isAvailable: true,
            isRecurring: true,
            displayUndiscountedPrice: false
        },
        {
            planId: SubscriptionPlans.ThreeMonth,
            name: '3 MONTH',
            type: SubscriptionPlanType.ThreeMonth,
            pricePerMonth: PlanMonthlyPrices.ThreeMonth,
            totalPrice: PlanMonthlyPrices.ThreeMonth * PlanDurations.ThreeMonth,
            duration: PlanDurations.ThreeMonth,
            iconClass: 'circle-6-12-icon',
            savings:
                PlanMonthlyPrices.OneMonth * PlanDurations.ThreeMonth -
                PlanMonthlyPrices.ThreeMonth * PlanDurations.ThreeMonth,
            isAvailable: false,
            isRecurring: true,
            displayUndiscountedPrice: false
        },
        {
            planId: SubscriptionPlans.SixMonth,
            name: '6 MONTH',
            type: SubscriptionPlanType.SixMonth,
            pricePerMonth: PlanMonthlyPrices.SixMonth,
            totalPrice: PlanMonthlyPrices.SixMonth * PlanDurations.SixMonth,
            duration: PlanDurations.SixMonth,
            iconClass: 'circle-12-12-icon',
            savings:
                PlanMonthlyPrices.OneMonth * PlanDurations.SixMonth -
                PlanMonthlyPrices.SixMonth * PlanDurations.SixMonth,
            isAvailable: false,
            isRecurring: true,
            displayUndiscountedPrice: false
        },
        {
            planId: SubscriptionPlans.TwelveMonth,
            name: 'Annual',
            type: SubscriptionPlanType.TwelveMonth,
            pricePerMonth: PlanMonthlyPrices.TwelveMonth,
            totalPrice: PlanMonthlyPrices.TwelveMonth * PlanDurations.TwelveMonth,
            duration: PlanDurations.TwelveMonth,
            iconClass: 'circle-12-12-icon',
            savings:
                PlanMonthlyPrices.OneMonth * PlanDurations.TwelveMonth -
                PlanMonthlyPrices.TwelveMonth * PlanDurations.TwelveMonth,
            isAvailable: true,
            isRecurring: true,
            displayUndiscountedPrice: false
        },
        {
            planId: SubscriptionPlans.Lifetime,
            name: 'Lifetime',
            type: SubscriptionPlanType.Lifetime,
            pricePerMonth: PlanMonthlyPrices.Lifetime,
            totalPrice: PlanTotalPrices.Lifetime,
            duration: PlanDurations.Lifetime,
            iconClass: 'circle-12-12-icon',
            savings: PlanMonthlyPrices.Lifetime,
            undiscountedPrice: PlanTotalPricesUndiscounted.Lifetime,
            isAvailable: true,
            isRecurring: false,
            displayUndiscountedPrice: false
        }
    ];
}
