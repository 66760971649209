export enum SubscriptionPlans {
    OneMonth = 'gl-plan-1',
    ThreeMonth = 'gl-plan-2',
    SixMonth = 'gl-plan-3',
    TwelveMonth = 'gl-plan-4',
    Lifetime = 'gl-plan-5'
}

export enum SubscriptionPlanType {
    OneMonth = 'oneMonth',
    ThreeMonth = 'threeMonth',
    SixMonth = 'sixMonth',
    TwelveMonth = 'twelveMonth',
    Lifetime = 'lifetime'
}

export enum PlanMonthlyPrices {
    OneMonth = 12.99,
    ThreeMonth = 5.99,
    SixMonth = 4.99,
    TwelveMonth = 6.99,
    Lifetime = 0
}

export enum PlanTotalPrices {
    Lifetime = 149.0,
    TwelveMonth = 83.88,
}

export enum PlanTotalPricesUndiscounted {
    Lifetime = 149.0
}

export enum PlanDurations {
    OneMonth = 1,
    ThreeMonth = 3,
    SixMonth = 6,
    TwelveMonth = 12,
    Lifetime = 0
}
export interface SubscriptionPlan {
    planId: SubscriptionPlans;
    name: string;
    type: SubscriptionPlanType;
    pricePerMonth: PlanMonthlyPrices;
    totalPrice: PlanTotalPrices | PlanMonthlyPrices;
    duration: PlanDurations;
    iconClass: string;
    savings?: number;
    isAvailable: boolean;
    isRecurring: boolean;
    displayUndiscountedPrice?: boolean;
    undiscountedPrice?: number;
}
