import { VideoGuide } from './video-guide.model';
import { Account } from './account.model';
import { RatedComment } from './rated-comment.model';

export enum CommentsSortingType {
    DATE = '-createdAt',
    RATING = '-rating'
}

export interface Comment {
    _id: string;
    itemId: string;
    item: VideoGuide;
    parentId: string;
    parent: Comment;
    author: Account;
    content: string;
    replies: any[];
    likes: number;
    dislikes: number;
    replyUser: Account & string;
    expertUserId: string;
    rating: number;
    isLiked: boolean;
    isDisliked: boolean;
    isDeleted: boolean;
    isReply: boolean;
    createdAt: Date;
    updatedAt: Date;
    game: string;
    metadata: {
        isNew?: boolean;
    };
}

export interface CommentMetadata {
    isLiked?: boolean;
    isDisliked?: boolean;
    ratedComment?: RatedComment;
    isNew?: boolean;
    // permits certain users to update/delete the comment
    isActionable?: boolean;
    isRateable?: boolean;
}

export interface CommentViewModel extends Omit<Comment, 'parent'> {
    parent: CommentViewModel | Comment;
    metadata: CommentMetadata;
}
