import { Injectable } from '@angular/core';
import { CommentsSortingType } from '../../models/comment.model';
import { Contact } from '../../models/contact.model';
import { Account } from '../../models/account.model';
import { Store } from '@ngrx/store';
import { GlobalState } from '../store';

@Injectable()
export class UserActions {
    static USER_ERROR_NEW = 'USER_ERROR_NEW';
    static USER_ERROR_RESET = 'USER_ERROR_RESET';

    static COMMENT_SORTING_TYPE_CHANGE = 'COMMENT_SORTING_TYPE_CHANGE';

    static EMAIL_PREFERENCES_UPDATE = 'EMAIL_PREFERENCES_UPDATE';
    static EMAIL_PREFERENCES_UPDATE_SUCCESS = 'EMAIL_PREFERENCES_UPDATE_SUCCESS';
    static EMAIL_PREFERENCES_UPDATE_RESET = 'EMAIL_PREFERENCES_UPDATE_RESET';
    static EMAIL_PREFERENCES_UPDATE_LOADING_CHANGED = 'EMAIL_PREFERENCES_UPDATE_LOADING_CHANGED';

    static GOOGLE_DISCONNECT = 'GOOGLE_DISCONNECT';
    static GOOGLE_DISCONNECT_SUCCESS = 'GOOGLE_DISCONNECT_SUCCESS';

    static FACEBOOK_DISCONNECT = 'FACEBOOK_DISCONNECT';
    static FACEBOOK_DISCONNECT_SUCCESS = 'FACEBOOK_DISCONNECT_SUCCESS';

    static BATTLE_NET_DISCONNECT = 'BATTLE_NET_DISCONNECT';
    static BATTLE_NET_DISCONNECT_SUCCESS = 'BATTLE_NET_DISCONNECT_SUCCESS';

    static DISCORD_DISCONNECT = 'DISCORD_DISCONNECT';
    static DISCORD_DISCONNECT_SUCCESS = 'DISCORD_DISCONNECT_SUCCESS';

    static USER_PROFILE_IMAGE_URL_SIGN = 'USER_PROFILE_IMAGE_URL_SIGN';
    static USER_PROFILE_IMAGE_URL_SIGN_SUCCESS = 'USER_PROFILE_IMAGE_URL_SIGN_SUCCESS';

    static USER_PROFILE_IMAGE_RESIZE = 'USER_PROFILE_IMAGE_RESIZE';
    static USER_PROFILE_IMAGE_RESIZE_SUCCESS = 'USER_PROFILE_IMAGE_RESIZE_SUCCESS';

    static USER_PROFILE_IMAGE_ID_NEW = 'USER_PROFILE_IMAGE_ID_NEW';
    static USER_PROFILE_IMAGE_ID_NEW_SUCCESS = 'USER_PROFILE_IMAGE_ID_NEW_SUCCESS';

    static EMAIL_PREFERENCES_GET = 'EMAIL_PREFERENCES_GET';
    static EMAIL_PREFERENCES_GET_SUCCESS = 'EMAIL_PREFERENCES_GET_SUCCESS';

    static USER_CONSENT_REGATHER = 'USER_CONSENT_REGATHER';
    static USER_CONSENT_REGATHER_SUCCESS = 'USER_CONSENT_REGATHER_SUCCESS';

    static USER_UPDATE = 'USER_UPDATE';
    static USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
    static USER_UPDATE_LOADING_CHANGED = 'USER_UPDATE_LOADING_CHANGED';


    constructor(private store: Store<GlobalState>) {
    }

    userErrorNew(errorData: any) {
        return {
            type: UserActions.USER_ERROR_NEW,
            payload: errorData
        };
    }

    userErrorReset(errorData: any) {
        return this.store.dispatch({
            type: UserActions.USER_ERROR_RESET,
            payload: errorData
        });
    }

    commentSortingTypeChange(sortingType: CommentsSortingType) {
        return this.store.dispatch({
            type: UserActions.COMMENT_SORTING_TYPE_CHANGE,
            payload: sortingType
        });
    }

    emailPreferencesUpdate(data: any) {
        return this.store.dispatch({
            type: UserActions.EMAIL_PREFERENCES_UPDATE,
            payload: data
        });
    }

    emailPreferencesUpdateSuccess(data: any) {
        return {
            type: UserActions.EMAIL_PREFERENCES_UPDATE_SUCCESS,
            payload: data
        };
    }

    emailPreferencesGet(id: string) {
        return this.store.dispatch({
            type: UserActions.EMAIL_PREFERENCES_GET,
            payload: id
        });
    }

    emailPreferencesGetSuccess(contact: Contact) {
        return {
            type: UserActions.EMAIL_PREFERENCES_GET_SUCCESS,
            payload: contact
        };
    }

    emailPreferencesUpdateReset() {
        return this.store.dispatch({
            type: UserActions.EMAIL_PREFERENCES_UPDATE_RESET
        });
    }

    emailPreferencesUpdateLoadingChanged(isLoading: boolean) {
        return this.store.dispatch({
            type: UserActions.EMAIL_PREFERENCES_UPDATE_LOADING_CHANGED,
            payload: isLoading
        });
    }

    googleDisconnect(userId: string) {
        return this.store.dispatch({
            type: UserActions.GOOGLE_DISCONNECT,
            payload: userId
        });
    }

    googleDisconnectSuccess(account: Account) {
        return {
            type: UserActions.GOOGLE_DISCONNECT_SUCCESS,
            payload: account
        };
    }

    facebookDisconnect(userId: string) {
        return this.store.dispatch({
            type: UserActions.FACEBOOK_DISCONNECT,
            payload: userId
        });
    }

    facebookDisconnectSuccess(account: Account) {
        return {
            type: UserActions.FACEBOOK_DISCONNECT_SUCCESS,
            payload: account
        };
    }

    battleNetDisconnect(userId: string) {
        return this.store.dispatch({
            type: UserActions.BATTLE_NET_DISCONNECT,
            payload: userId
        });
    }

    battleNetDisconnectSuccess(account: Account) {
        return {
            type: UserActions.BATTLE_NET_DISCONNECT_SUCCESS,
            payload: account
        };
    }

    discordDisconnect(userId: string) {
        return this.store.dispatch({
            type: UserActions.DISCORD_DISCONNECT,
            payload: userId
        });
    }

    discordDisconnectSuccess(account: Account) {
        return {
            type: UserActions.DISCORD_DISCONNECT_SUCCESS,
            payload: account
        };
    }

    userProfileImageUrlSign(data: any) {
        return this.store.dispatch({
            type: UserActions.USER_PROFILE_IMAGE_URL_SIGN,
            payload: data
        });
    }

    userProfileImageUrlSignSuccess(data: any) {
        return {
            type: UserActions.USER_PROFILE_IMAGE_URL_SIGN_SUCCESS,
            payload: data
        };
    }

    userProfileImageResize(data: any) {
        return this.store.dispatch({
            type: UserActions.USER_PROFILE_IMAGE_RESIZE,
            payload: data
        });
    }

    userProfileImageResizeSuccess() {
        return {
            type: UserActions.USER_PROFILE_IMAGE_RESIZE_SUCCESS
        };
    }

    userProfileImageIdNew(data: { userId: string; data: any }) {
        return this.store.dispatch({
            type: UserActions.USER_PROFILE_IMAGE_ID_NEW,
            payload: data
        });
    }

    userProfileImageIdNewSuccess(data: any) {
        return {
            type: UserActions.USER_PROFILE_IMAGE_ID_NEW_SUCCESS,
            payload: data
        };
    }

    userConsentRegather(updateData: any) {
        return this.store.dispatch({
            type: UserActions.USER_CONSENT_REGATHER,
            payload: updateData
        });
    }

    userConsentRegatherSuccess(account: Account) {
        return {
            type: UserActions.USER_CONSENT_REGATHER_SUCCESS,
            payload: account
        };
    }

    userUpdate(userData: { userId: string; data: any }) {
        return this.store.dispatch({
            type: UserActions.USER_UPDATE,
            payload: userData
        });
    }

    userUpdateSuccess(user: Account) {
        return {
            type: UserActions.USER_UPDATE_SUCCESS,
            payload: user
        };
    }

    userUpdateLoadingChanged(isLoading: boolean) {
        return this.store.dispatch({
            type: UserActions.USER_UPDATE_LOADING_CHANGED,
            payload: isLoading
        });
    }

}
