import packageInfo from '../../package.json';

export const environment = {
    version: packageInfo.version,
    production: true,
    test: true,
    type: 'test',
    apiRoot: 'https://test-api.gameleap.com/api',
    clientRoot: 'https://test.gameleap.com',
    clientDomain: 'test.gameleap.com',
    clientRootServer: 'https://test.gameleap.com',
    socketRoot: 'https://test-api.gameleap.com:443',
    rootCDN: 'https://test-cdn.gameleap.com',
    rootAssets: 'https://test.gameleap.com',
    braintreeTokenizationKey: 'sandbox_mkqsy2t6_gm49b2ckqzb3v2mk',
    flowplayerKey: '$609996136765771',
    traceRouterScrolling: false,
    hmr: false
};

