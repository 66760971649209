import { createAction, props } from '@ngrx/store';
import { Game } from '../../core/constants/game.enum';
import { APIError } from '../../models/error.model';
import { User } from '../../models/user.model';

export enum AuthActionsTypes {
    LoginUser = '[Login Page] Login User',
    LoginUserSuccess = '[Auth API] Login User Success',
    LoginUserFailure = '[Auth API] Login User Failure',

    LogoutUser = '[App Header Dropdown] Logout User',
    LogoutUserInterceptor = '[Auth Interceptor] Logout User',
    LogoutUserSuccess = '[Auth API] Logout User Success',
    LogoutUserFailure = '[Auth API] Logout User Failure',

    ConfirmLogin = '[App Global] Confirm Login',
    ConfirmLoginSuccess = '[Auth API] Confirm Login Success',
    ConfirmLoginFailure = '[Auth API] Confirm Login Failure',

    SignupUser = '[App Global] Signup User',
    SignupUserSuccess = '[Auth API] Signup User Success',
    SignupUserFailure = '[Auth API] Signup User Failure',

    PasswordResetInitiate = '[Password Reset Initiate Page] Password Reset Initiate',
    PasswordResetInitiateSuccess = '[Auth API] Password Reset Initiate Success',
    PasswordResetInitiateFailure = '[Auth API] Password Reset Initiate Failure',

    PasswordReset = '[Password Reset Page] Password Reset',
    PasswordResetSuccess = '[Auth API] Password Reset Success',
    PasswordResetFailure = '[Auth API] Password Reset Failure'
}

export const loginUser = createAction(
    AuthActionsTypes.LoginUser,
    props<{ payload: { credential: string; password: string; recaptchaToken: string } }>()
);
export const loginUserSuccess = createAction(
    AuthActionsTypes.LoginUserSuccess,
    props<{ payload: User }>()
);
export const loginUserFailure = createAction(
    AuthActionsTypes.LoginUserFailure,
    props<{ payload: APIError }>()
);

export const logoutUser = createAction(AuthActionsTypes.LogoutUser);
export const logoutUserInterceptor = createAction(AuthActionsTypes.LogoutUserInterceptor);
export const logoutUserSuccess = createAction(AuthActionsTypes.LogoutUserSuccess);
export const logoutUserFailure = createAction(
    AuthActionsTypes.LogoutUserFailure,
    props<{ payload: APIError }>()
);

export const confirmLogin = createAction(
    AuthActionsTypes.ConfirmLogin,
    props<{ payload: { reIssueToken?: boolean } }>() // optional
);
export const confirmLoginSuccess = createAction(
    AuthActionsTypes.ConfirmLoginSuccess,
    props<{ payload: User }>()
);
export const confirmLoginFailure = createAction(
    AuthActionsTypes.ConfirmLoginFailure,
    props<{ payload: APIError }>()
);

export const signupUser = createAction(
    AuthActionsTypes.SignupUser,
    props<{
        payload: {
            username: string;
            email: string;
            password: string;
            emailsConsent: boolean;
            game: Game | null | undefined;
            reCaptchaToken: string | null;
            affiliateId?: string;
            campaignId?: string;
            accounts?: any;
        };
    }>()
);
export const signupUserSuccess = createAction(
    AuthActionsTypes.SignupUserSuccess,
    props<{ payload: User }>()
);
export const signupUserFailure = createAction(
    AuthActionsTypes.SignupUserFailure,
    props<{ payload: APIError }>()
);

export const passwordResetInitiate = createAction(
    AuthActionsTypes.PasswordResetInitiate,
    props<{ payload: { email: string } }>()
);
export const passwordResetInitiateSuccess = createAction(
    AuthActionsTypes.PasswordResetInitiateSuccess
);
export const passwordResetInitiateFailure = createAction(
    AuthActionsTypes.PasswordResetInitiateFailure,
    props<{ payload: APIError }>()
);

export const passwordReset = createAction(
    AuthActionsTypes.PasswordReset,
    props<{ payload: { token: string; newPassword: string } }>()
);
export const passwordResetSuccess = createAction(AuthActionsTypes.PasswordResetSuccess);
export const passwordResetFailure = createAction(
    AuthActionsTypes.PasswordResetFailure,
    props<{ payload: APIError }>()
);

