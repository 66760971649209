{
    "name": "web",
    "version": "5.0.0",
    "license": "UNLICENSED",
    "scripts": {
        "ng": "ng",
        "start": "ng serve --project=gl --configuration=dev --host=0.0.0.0 --port=4000 --poll=500 --disable-host-check",
        "start-without-poll": "ng serve --configuration=dev --host=0.0.0.0 --port=4000 --disable-host-check",
        "start-without-poll-hmr": "ng serve --configuration=hmr --host=0.0.0.0 --port=4000 --disable-host-check",
        "build": "node --max-old-space-size=8192 ./node_modules/@angular/cli/bin/ng build",
        "run": "node --max-old-space-size=8192 ./node_modules/@angular/cli/bin/ng run",
        "fix-ngsw": "bash ./fix-ngsw.sh",
        "build:test": "npm run build -- --project=gl --configuration=test --verbose && npm run fix-ngsw && npm run postbuild",
        "build:dev": "npm run build -- --project=gl --aot && npm run fix-ngsw",
        "build:prod": "npm run build -- --project=gl --configuration=production && npm run fix-ngsw && npm run postbuild",
        "build:ssr": "npm run build && npm run build:ingress && ng run gl:server",
        "build:ssr:prod": "npm run build -- --configuration=production && npm run fix-ngsw && npm run postbuild && npm run build:ingress && npm run run gl:server:production",
        "build:ssr:test": "npm run build -- --configuration=test && npm run fix-ngsw && npm run postbuild && npm run build:ingress && npm run run gl:server:test",
        "build:ingress": "npx tsc -p src/tsconfig.ingress.json",
        "webpack:ssr": "webpack --config webpack.server.config.js --progress --colors",
        "start:ssr": "npm run build:ssr && npm run postbuild && npm run serve:ssr",
        "serve:ssr": "node dist/server/main.js",
        "test": "ng test",
        "lint": "ng lint",
        "e2e": "ng e2e",
        "precommit": "lint-staged",
        "dev:ssr": "node --max-old-space-size=8192 ./node_modules/@angular/cli/bin/ng run gl:serve-ssr --host=0.0.0.0",
        "prerender": "ng run gl:prerender",
        "postbuild": "npm run build:material:theme && node brotli-compress.js",
        "build:material:theme": "sass src/styles/_gameleap_mat_theme.scss --load-path=node_modules src/assets/css/gameleap_mat_theme.css"
    },
    "lint-staged": {
        "src/**/*.ts": [
            "tslint -c tslint-base.json",
            "git add"
        ]
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "^17.0.2",
        "@angular/cdk": "^17.0.0",
        "@angular/common": "^17.0.2",
        "@angular/compiler": "^17.0.2",
        "@angular/compiler-cli": "^17.0.2",
        "@angular/core": "^17.0.2",
        "@angular/forms": "^17.0.2",
        "@angular/material": "^17.0.0",
        "@angular/platform-browser": "^17.0.2",
        "@angular/platform-browser-dynamic": "^17.0.2",
        "@angular/platform-server": "^17.0.2",
        "@angular/pwa": "^17.0.0",
        "@angular/router": "^17.0.2",
        "@angular/service-worker": "^17.0.2",
        "@angular/ssr": "^17.0.0",
        "@gameleap/plyr": "^3.7.2",
        "@google-cloud/logging-winston": "^5.0.0",
        "@kolkov/angular-editor": "^2.0.0",
        "@ngrx/effects": "^16.3.0",
        "@ngrx/entity": "^16.3.0",
        "@ngrx/router-store": "^16.3.0",
        "@ngrx/store": "^16.3.0",
        "@ngrx/store-devtools": "^16.3.0",
        "@swimlane/ngx-charts": "^20.1.2",
        "@tinymce/tinymce-angular": "^7.0.0",
        "angular-draggable-droppable": "^8.0.0",
        "angular-l10n": "^16.0.0",
        "braintree-web": "^3.76.4",
        "express": "^4.15.2",
        "express-rate-limit": "^7.1.0",
        "express-static-gzip": "^2.1.7",
        "flatted": "^3.2.7",
        "http-proxy": "^1.18.1",
        "localforage": "^1.9.0",
        "lodash-es": "^4.17.21",
        "lru-cache": "^10.1.0",
        "material-symbols": "^0.4.2",
        "minimatch": "^9.0.3",
        "mongoose": "^8.0.0",
        "ng-recaptcha": "^12.0.0",
        "ng2-file-upload": "^5.0.0",
        "ngrx-store-localstorage": "^16.0.0",
        "ngx-clipboard": "^16.0.0",
        "ngx-device-detector": "^6.0.0",
        "ngx-drag-to-select": "^5.0.1",
        "ngx-logger": "^5.0.12",
        "ngx-scrollbar": "^13.0.0",
        "rate-limit-mongo": "^2.3.2",
        "rate-limit-redis": "^4.1.2",
        "redis": "^4.6.10",
        "rxjs": "^7.8.0",
        "sass": "^1.34.0",
        "shortid": "^2.2.16",
        "slugify": "^1.6.6",
        "socket.io-client": "^4.6.1",
        "ssr-window": "^4.0.2",
        "tslib": "^2.2.0",
        "winston": "^3.11.0",
        "zone.js": "^0.14.2"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^17.0.0",
        "@angular/cli": "^17.0.0",
        "@angularclass/hmr": "^3.0.0",
        "@types/cache-manager": "^4.0.5",
        "@types/compression": "^1.7.5",
        "@types/express": "^4.17.0",
        "@types/gapi": "^0.0.36",
        "@types/gapi.auth2": "^0.0.50",
        "@types/http-proxy": "^1.17.14",
        "@types/jasmine": "^3.7.4",
        "@types/jasminewd2": "^2.0.9",
        "@types/mongoose": "^5.11.97",
        "@types/node": "^12.20.13",
        "@types/shortid": "^0.0.29",
        "brotli-webpack-plugin": "^1.1.0",
        "codelyzer": "^6.0.2",
        "compression-webpack-plugin": "^10.0.0",
        "husky": "2.4.0",
        "image-minimizer-webpack-plugin": "^3.8.3",
        "jasmine-spec-reporter": "~5.0.0",
        "karma": "~6.4.1",
        "karma-chrome-launcher": "~3.1.0",
        "karma-cli": "~2.0.0",
        "karma-coverage-istanbul-reporter": "~3.0.2",
        "karma-jasmine": "~4.0.0",
        "karma-jasmine-html-reporter": "^1.6.0",
        "lint-staged": "8.2.0",
        "ngx-window-token": "^2.0.1",
        "prettier": "^1.19.1",
        "pretty-quick": "1.11.0",
        "protractor": "~7.0.0",
        "sharp": "^0.32.6",
        "ts-loader": "^6.2.2",
        "ts-node": "~8.2.0",
        "tslint": "~6.1.0",
        "typescript": "^5.2.2",
        "webpack": "^5.0.0"
    }
}
