import { createSelector } from '@ngrx/store';
import { Account, Role } from '../../models/account.model';
import { CommentsSortingType } from '../../models/comment.model';
import { Contact } from '../../models/contact.model';
import { AuthActionsTypes } from '../actions/auth.actions';
import { UserActions } from '../actions/user.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { GlobalState } from '../store';

export interface UserState {
    account: Account | null | any;
    isLoggedIn: boolean;
    isAdmin: boolean;
    isManager: boolean;
    isWriter: boolean;
    isPremium: boolean;
    isPlayer: boolean;
    isTrial: boolean;
    isFree: boolean;
    isInfluencer: boolean;
    roles: Role[];
    commentsSortingType: CommentsSortingType;
    contact: Contact;
    contactLoading: {
        dota: {
            receivePromotionalEmails: {
                loading: boolean;
                success: boolean;
            };
            receiveReplyEmails: {
                loading: boolean;
                success: boolean;
            };
            receiveVideoGuideEmails: {
                loading: boolean;
                success: boolean;
            };
        };
        overwatch: {
            receivePromotionalEmails: {
                loading: boolean;
                success: boolean;
            };
            receiveReplyEmails: {
                loading: boolean;
                success: boolean;
            };
            receiveVideoGuideEmails: {
                loading: boolean;
                success: boolean;
            };
        };
        league: {
            receivePromotionalEmails: {
                loading: boolean;
                success: boolean;
            };
            receiveReplyEmails: {
                loading: boolean;
                success: boolean;
            };
            receiveVideoGuideEmails: {
                loading: boolean;
                success: boolean;
            };
        };
    };
    profileData: {
        loading: boolean;
    };
    userUpdateLoading: boolean;
}

export const userInitialState: UserState = {
    account: null,
    isLoggedIn: false,
    isAdmin: false,
    isManager: false,
    isWriter: false,
    isPremium: false,
    isTrial: false,
    isFree: true,
    isPlayer: false,
    isInfluencer: false,
    roles: [],
    commentsSortingType: CommentsSortingType.RATING,
    contact: {
        _id: '',
        email: '',
        dota: {
            receivePromotionalEmails: false,
            receiveReplyEmails: false,
            receiveVideoGuideEmails: false
        },
        overwatch: {
            receivePromotionalEmails: false,
            receiveReplyEmails: false,
            receiveVideoGuideEmails: false
        },
        league: {
            receivePromotionalEmails: false,
            receiveReplyEmails: false,
            receiveVideoGuideEmails: false
        }
    },
    contactLoading: {
        dota: {
            receivePromotionalEmails: {
                loading: false,
                success: false
            },
            receiveReplyEmails: {
                loading: false,
                success: false
            },
            receiveVideoGuideEmails: {
                loading: false,
                success: false
            }
        },
        overwatch: {
            receivePromotionalEmails: {
                loading: false,
                success: false
            },
            receiveReplyEmails: {
                loading: false,
                success: false
            },
            receiveVideoGuideEmails: {
                loading: false,
                success: false
            }
        },
        league: {
            receivePromotionalEmails: {
                loading: false,
                success: false
            },
            receiveReplyEmails: {
                loading: false,
                success: false
            },
            receiveVideoGuideEmails: {
                loading: false,
                success: false
            }
        }
    },
    profileData: {
        loading: false
    },
    userUpdateLoading: false
};

export function userReducer(state: UserState = userInitialState, action: PayloadAction): UserState {
    switch (action.type) {
        case AuthActionsTypes.ConfirmLoginSuccess:
        case AuthActionsTypes.LoginUserSuccess:
        case AuthActionsTypes.SignupUserSuccess:
            return {
                ...state,
                ...action.payload
            };
        case UserActions.EMAIL_PREFERENCES_GET_SUCCESS:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    ...action.payload
                }
            };
        case UserActions.EMAIL_PREFERENCES_UPDATE:
            const contactLoadingObj = {};

            Object.keys(action.payload.data).forEach(key => {
                const [game, propName] = key.split('.');
                contactLoadingObj[game] = {};
                contactLoadingObj[game][propName] = {
                    loading: true,
                    success: false
                };
                contactLoadingObj[game] = {
                    ...state.contactLoading[game],
                    ...contactLoadingObj[game]
                };
            });
            return {
                ...state,
                contactLoading: {
                    ...state.contactLoading,
                    ...contactLoadingObj
                }
            };
        case UserActions.EMAIL_PREFERENCES_UPDATE_SUCCESS:
            const contactLoading = { ...state.contactLoading };

            Object.keys(contactLoading).forEach(game => {
                const gameData = contactLoading[game];

                Object.keys(gameData).forEach(propName => {
                    contactLoading[game] = { ...contactLoading[game] };
                    if (contactLoading[game][propName].loading) {
                        contactLoading[game][propName] = {
                            ...contactLoading[game][propName],
                            loading: false,
                            success: true
                        };
                    }
                });
            });

            return {
                ...state,
                contact: { ...state.contact, ...action.payload },
                contactLoading: contactLoading
            };
        case UserActions.EMAIL_PREFERENCES_UPDATE_RESET:
            return {
                ...state,
                contactLoading: userInitialState.contactLoading
            };
        case UserActions.GOOGLE_DISCONNECT_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    accounts: {
                        ...state.account.accounts,
                        google: action.payload.accounts.google
                    }
                }
            };
        case UserActions.FACEBOOK_DISCONNECT_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    accounts: {
                        ...state.account.accounts,
                        fb: action.payload.accounts.fb
                    }
                }
            };
        case UserActions.DISCORD_DISCONNECT_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    accounts: {
                        ...state.account.accounts,
                        discord: action.payload.accounts.discord
                    }
                }
            };
        case UserActions.BATTLE_NET_DISCONNECT_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    accounts: {
                        ...state.account.accounts,
                        bnet: action.payload.accounts.bnet
                    }
                }
            };
        case UserActions.USER_PROFILE_IMAGE_URL_SIGN_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    profileImageSignedUrl: action.payload.signedUrl,
                    profileImageId: action.payload.key,
                    profileImageResized: false
                }
            };
        case UserActions.USER_PROFILE_IMAGE_RESIZE_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    profileImageResized: true
                }
            };
        case UserActions.USER_PROFILE_IMAGE_ID_NEW_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    profileImagePath: action.payload
                }
            };
        case AuthActionsTypes.LogoutUser:
            return {
                ...userInitialState
            };
        case AuthActionsTypes.LogoutUserInterceptor:
            return {
                ...userInitialState
            };
        case UserActions.COMMENT_SORTING_TYPE_CHANGE:
            return {
                ...state,
                commentsSortingType: action.payload
            };
        case UserActions.USER_CONSENT_REGATHER_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    consentGrants: action.payload.consentGrants
                }
            };
        case UserActions.USER_UPDATE_SUCCESS:
            return {
                ...state,
                account: {
                    ...state.account,
                    ...action.payload
                }
            };
        case UserActions.USER_UPDATE_LOADING_CHANGED:
            return {
                ...state,
                userUpdateLoading: action.payload
            };
        default:
            return state;
    }
}

const getUserAccount = (state: GlobalState) => state.user.account;
const getUser = (state: GlobalState) => state.user;
const getIsLoggedIn = (state: GlobalState) => state.user.isLoggedIn;
const getIsAdmin = (state: GlobalState) => state.user.isAdmin;
const getIsManager = (state: GlobalState) => state.user.isManager;
const getIsPremium = (state: GlobalState) => state.user.isPremium;
const getIsTrial = (state: GlobalState) => state.user.isTrial;
const getIsFree = (state: GlobalState) => state.user.isFree;
const getIsPlayer = (state: GlobalState) => state.user.isPlayer;
const getIsInfluencer = (state: GlobalState) => state.user.isInfluencer;
const getAllRoles = (state: GlobalState) => state.user.roles;
const getProfileDataLoading = (state: GlobalState) => state.user.profileData.loading;
const getUserUpdateLoading = (state: GlobalState) => state.user.userUpdateLoading;
const getContact = (state: GlobalState) => state.user.contact;
const getContactLoading = (state: GlobalState) => state.user.contactLoading;

export const userAccountSelector = createSelector([getUserAccount], account => account);
export const userSelector = createSelector([getUser], user => user);
export const isLoggedInSelector = createSelector([getIsLoggedIn], isLoggedIn => isLoggedIn);
export const userRolesSelector = createSelector(
    [
        getIsAdmin,
        getIsManager,
        getIsPremium,
        getIsTrial,
        getIsFree,
        getIsPlayer,
        getIsInfluencer,
        getAllRoles
    ],
    (
        isAdmin: boolean,
        isManager: boolean,
        isPremium: boolean,
        isTrial: boolean,
        isFree: boolean,
        isPlayer: boolean,
        isInfluencer: boolean,
        allRoles: Role[]
    ) => {
        return {
            isAdmin,
            isManager,
            isPremium,
            isTrial,
            isFree,
            isPlayer,
            isInfluencer,
            allRoles
        };
    }
);
export const profileChangeDataLoadingSelector = createSelector(
    [getProfileDataLoading],
    loading => loading
);
export const userUpdateLoadingSelector = createSelector([getUserUpdateLoading], loading => loading);
export const contactSelector = createSelector([getContact], contact => contact);

export const contactLoadingSelector = createSelector(
    [getContactLoading],
    loadingPreferences => loadingPreferences
);

